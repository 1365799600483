import _reactProductionMin from "./cjs/react.production.min.js";
export { _reactProductionMin as default };
export const Children = _reactProductionMin.Children,
  Component = _reactProductionMin.Component,
  Fragment = _reactProductionMin.Fragment,
  Profiler = _reactProductionMin.Profiler,
  PureComponent = _reactProductionMin.PureComponent,
  StrictMode = _reactProductionMin.StrictMode,
  Suspense = _reactProductionMin.Suspense,
  __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = _reactProductionMin.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
  cloneElement = _reactProductionMin.cloneElement,
  createContext = _reactProductionMin.createContext,
  createElement = _reactProductionMin.createElement,
  createFactory = _reactProductionMin.createFactory,
  createRef = _reactProductionMin.createRef,
  forwardRef = _reactProductionMin.forwardRef,
  isValidElement = _reactProductionMin.isValidElement,
  lazy = _reactProductionMin.lazy,
  memo = _reactProductionMin.memo,
  useCallback = _reactProductionMin.useCallback,
  useContext = _reactProductionMin.useContext,
  useDebugValue = _reactProductionMin.useDebugValue,
  useEffect = _reactProductionMin.useEffect,
  useImperativeHandle = _reactProductionMin.useImperativeHandle,
  useLayoutEffect = _reactProductionMin.useLayoutEffect,
  useMemo = _reactProductionMin.useMemo,
  useReducer = _reactProductionMin.useReducer,
  useRef = _reactProductionMin.useRef,
  useState = _reactProductionMin.useState,
  version = _reactProductionMin.version;